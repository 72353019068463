import React from 'react'
import { RichText } from 'prismic-reactjs'

import { 
    Box
} from '@chakra-ui/react'

import { CustomLink } from '../../utils/customLink'

const BlogPostSliceBlock = ({ slice }) => {
  return (

    <Box>
      {slice.items.map((post) => {
        console.log(post)
        return (
          <Box
                backgroundColor="rgba(255, 255, 255, 0.8)"
                padding={{ sm: '8' }}
              >
          <RichText render={post.post_content.raw} serializeHyperlink={CustomLink} />
          </Box>
        )
      })}
      
      </Box>
    )
}

export default BlogPostSliceBlock