import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import {
    Box,
    Flex,
    Container,
    Heading,
    Text
} from '@chakra-ui/react'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'

const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      gap: 0
      //slidesToSlide: 4 // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    }
  };

const Testimonials = ({ slice }) => {

  const testimonials = slice.primary.testimonial.document.data.testimonial
  console.log(testimonials)

    return (
        <Box>
            <Heading as="h2" color="sunset.500" textAlign="center">{slice.primary.testimonial.document.data.title.text}</Heading>
            <Box>
            <Container maxW="container.md">
                <Carousel
                swipeable={true}
                showDots={false}
                responsive={responsive}
                ssr={true}
                infinite={true}
                autoPlay={true}
                autoPlaySpeed={5000}
                centerMode={false}
                showDots
                //containerClass="carousel-container"
                removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
                >
            {testimonials.map((testimonial) => {
                return (
                    <Box>
                    <Flex flexDirection="column" p="4" justifyContent="space-between" alignItems="center" w="100%" h="100%">
                        <Box width="150px" h="150px" textAlign="center" mb="4">
                            <GatsbyImage image={getImage(testimonial.photo.localFile)} alt="" />
                        </Box>
                        <Box>
                            <Text fontSize="1.5rem">
                                {`"${testimonial.quote.text}"`}
                            </Text>
                        </Box>
                        <Box>
                            <Text fontSize="1.375rem" fontWeight="900">
                                {testimonial.person_name.text}
                            </Text>
                        </Box>
                        <Box>
                            <Text fontSize="1.2rem" fontStyle="italic">
                                {testimonial.person_title.text}
                            </Text>
                        </Box>
                    </Flex>
                    </Box>
                )
            })}
                </Carousel>
            </Container>
            </Box>
        </Box>
    )
}

export default Testimonials;