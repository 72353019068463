import React from 'react'
import {
    Box,
    Heading,
    Flex,
    Text,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure
} from '@chakra-ui/react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { RichText } from 'prismic-reactjs'
import { ExternalLinkIcon } from '@chakra-ui/icons'
import styled from '@emotion/styled'

const StyledStaffModal = styled(Box)`
    &:hover {
        transform: scale(1.03);
    }
    &:hover > div > div h4 {
        color: #eaa996;
    }
    &:hover > div > div p {
        color: #eaa996;
    }
`;

const StaffModal = (props) => {
    const { isOpen, onOpen, onClose } = useDisclosure();

    // fixes scroll to top when modal closes
    const finalRef = React.useRef()

    return (
        <>
        
        <StyledStaffModal ref={finalRef}  p="2" textAlign="center" width={{base: '100%', md: '50%', lg: '25%'}} onClick={onOpen} cursor="pointer">
                <Flex flexDirection="column"
                    flexGrow="1"
                    justifyContent="space-between"
                    color="white"
                    textAlign="center"
                    h="100%"
                    p="2" backgroundColor="brand.400">
                <Box mb="4">
                                <GatsbyImage image={getImage(props.staffPhoto)} alt={props.photoAlt} />
                            </Box>
                           <Box>
                                <Heading as="h4" fontSize="1.5rem">
                                {props.staffName}
                            </Heading>
                           </Box>
                        <Box mt="auto"><Text>{props.staffTitle} <ExternalLinkIcon /></Text> </Box>
                </Flex>
                            
                        
            </StyledStaffModal>
                        <Modal finalFocusRef={finalRef}  isOpen={isOpen} onClose={onClose} isCentered size="xl" scrollBehavior="inside">
                        <ModalOverlay />
                        <ModalContent>
                        <ModalHeader>{""}</ModalHeader>
                            <ModalCloseButton />
                            <ModalBody>
                                <Flex>
                                    <Box width="33%" mr="2">
                                        <GatsbyImage image={getImage(props.staffPhoto)} alt={props.photoAlt} />
                                    </Box>
                                    <Box>
                                        <Heading as="h3">
                                            {props.staffName}
                                        </Heading>
                                        <Heading as="h4" fontSize="1.75rem">
                                            {props.staffTitle}
                                        </Heading>
                                    </Box>
                                </Flex>
                                <Box mt="4">
                                <RichText render={props.staffContent} />
                                </Box>
                                
                            </ModalBody>
                            </ModalContent>
                        </Modal>
        </>
    )
}


const StaffBoardSlice = ({ slice }) => {
    
    return (
        <Box my={8}>
            <Box>
                <Heading as="h1" color="sunset.500" textAlign="center" textTransform="uppercase" id={slice.primary.selector_id.text}>
                    {slice.primary.title.text}
                </Heading>
            </Box>
            <Flex flexWrap="wrap">
                {slice.items.map((item) => {
                    return (
                        <StaffModal 
                            staffPhoto={item.image.localFile}
                            photoAlt={item.image.alt}
                            staffName={item.name.text}
                            staffTitle={item.title.text}
                            staffContent={item.content.raw}
                        />
                    )
                }
                )}
            </Flex>
        </Box>
    )
}

export default StaffBoardSlice