import React from 'react'
import { Link } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import CtaButton from '../Buttons/CtaButton'
import DonateButton from '../Buttons/DonateButton'
import TakeActionButton from '../Buttons/TakeActionButton'
import { linkResolver } from '../../utils/linkResolver'
import { CustomLink } from '../../utils/customLink'

import { 
    Box,
    Flex,
    Heading,
    Center,
    Text
} from '@chakra-ui/react'

const GetInvolvedBlock = ({ slice, element, data }) => {
  // console.log(`Element: ${JSON.stringify(element)}`)
    return (
        <Box maxW="100%">
           <Box w="100%" position="relative">
            <GatsbyImage image={getImage(slice.primary.background_image.localFile)} alt="" />
            <Box position="absolute" w="100%" top="50%" left="50%" style={{ transform: "translate(-50%, -50%)" }}>
              <Heading color="white" textAlign="center" fontSize={{sm: '1.5rem', md: '2rem', lg: '3rem'}}>
                {slice.primary.title.text}
              </Heading>
              <Text color="white"
              textAlign="center"
              fontSize={{ base: "1rem", md: "1.25rem " }}>
                {slice.primary.paragraph.text}
              </Text>
             <Center>
            <Flex mt="8"
                  //width={{md: '100%', lg: '55%'}}
                  w="100%"
                  justifyContent={{ sm: 'center', md: 'center', lg: 'center'}}
                  flexWrap="wrap">
              {slice.items ? (slice.items.map((ctaButton) => {
                console.log(ctaButton)
                return (
                  <Box m="2">
                    { ctaButton.button_label.includes("Donate") ? (
                        <DonateButton 
                          colorScheme={ctaButton.button_color}
                          btnText={ctaButton.button_label}
                        />
                          ) : (
                  <TakeActionButton
                    size="md"
                    colorScheme={ctaButton.button_color} 
                    btnText={ctaButton.button_label}
                    // this method works with the linkResolver if creating dynamic pages
                    //linkTo={ctaButton.button_link.url}
                    
                    // otherwise: 
                    //linkTo={ctaButton.button_link.uid}
                    
                    // create routes with hash for internally linking to tabs
                    // TODO: create a custom link component that can handle this
                    linkTo={
                      ctaButton.button_label === 'Contact Local Leaders'
                      ? (`${ctaButton.button_link.uid}#contact-local-decision-makers`)
                      : ctaButton.button_label === 'How To Comment'
                      ? (`${ctaButton.button_link.uid}#tips-for-public-speaking`)
                      : (ctaButton.button_link.uid)
                    }
                />
                )
              }
                </Box>
              )
              })) : null }
              </Flex>
              </Center>
            </Box>
            </Box>
        </Box>
    )
}


export default GetInvolvedBlock