import React from 'react'

import { 
    Box,
    Heading,
    Text,
    Center,
} from '@chakra-ui/react'
import NewsletterModal from '../NewsletterSignupBlock/NewsletterModal'

const NewsletterBlock = ({ slice }) => {
    return (
    <Box bg="steel.400" p={{sm: '6', md: '12'}}>
    <Box textAlign="center">
      <Heading color="white" fontSize="2rem">
        {slice.primary.title.text}
      </Heading>
      <Text color="white">
        {slice.primary.paragraph.text}
      </Text>
    </Box>
    
        <Box mt="2">
            <Center>
                <NewsletterModal 
                 size="lg"
                 colorScheme={slice.primary.button_color} 
                 btnText={slice.primary.button_text.text}
                 modalTitle="Sign Up For Our Newsletter"
                />
            </Center>
      </Box>              
    
    </Box>
     )
    }



export default NewsletterBlock