
// Required for Prismic 

exports.linkResolver = (doc) => {

  if (doc.type === 'page' && doc.uid === "featured-project") {
    return `/our-work/featured-project`
  }

    if (doc.uid === "timeline") {
      return `/about/timeline`
    }

    if (doc.uid === "staff-and-board") {
      return `/about/staff-and-board`
    }

    if (doc.type === 'community_collaboration_pages') {
      return `/our-work/community-collaboration/${doc.uid}`
    }

    if (doc.type === 'watchdogging_page') {
      return `/our-work/watchdogging/${doc.uid}`
    }

    if (doc.type === 'hoa_services_pages') {
      return `/our-work/hoa-services/${doc.uid}`
    }

    if (doc.type === 'legacy_pages') {
      return `/our-work/legacy/${doc.uid}`
    }

    if (doc.type === 'resources_page') {
      return `/resources`
    }

    if (doc.type === 'grand_targhee') {
      return `/our-work/grand-targhee`
    }

    if (doc.type === 'page' && doc.uid === 'news') {
      return `/news`
    }

    if (doc.type === 'page') {
      return `/${doc.uid}`
    }

    return '/'
  }
  