import React from 'react';
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import WindowIcon from '../Milestones/WindowIcon'
import { RichText } from 'prismic-reactjs'
import { CustomLink } from '../../utils/customLink'

const MilestonesBlock = ({ slice }) => {
    return (
        <VerticalTimeline>

            {slice.items.map((item) => {
                return (
                    <>
                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        contentStyle={{ background: '#323F07', color: 'white', borderRadius: '0'}}
                        contentArrowStyle={{ borderRight: '7px solid #323F07'}}
                        date={item.date.text}
                        iconStyle={{ background: '#323F07', color: 'white'}}
                        icon={<WindowIcon />}
                    >
                        <h4 className="vertical-timeline-element-title">{item.title.text}</h4>
                        <h3 className="vertical-timeline-element-subtitle">{item.location.text}</h3>

                        <RichText render={item.content.raw} serializeHyperlink={CustomLink} />
                        
                    </VerticalTimelineElement>
                    </>
                )
            })}

        </VerticalTimeline>
    )
}

export default MilestonesBlock;
