import React from 'react'
import { Box, Flex, Heading, Text, Button, Link } from '@chakra-ui/react'
import { Link as GatsbyLink } from 'gatsby'

import { FaChevronRight } from "react-icons/fa"
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

const ProjectCards = ({ slice }) => {
    return (
        <Box padding="4" backgroundColor="rgba(255, 255, 255, 0.8)">

        <Flex
            flexWrap="wrap"
                >        
        {slice.items.map((item) => {
            console.log(item)
            return (
                <>
                
            {item.featured_project === true ? (
                <>
            <Box mb={4} display={{sm: 'none', md: 'none', lg: 'block'}} w="100%">
                <Flex px={{base: 0, lg: '1'}} w="100%">
                    <Box w="50%">
                        <GatsbyImage image={getImage(item.project_image.localFile)} alt={item.project_image.alt} />
                    </Box>
                    <Box  w="50%"  px="4" bg="brand.400" color="white">
                    <Box p="4">
                        <Heading as="h4" 
                        fontSize="2rem"
                        >
                            {item.project_title.text}
                        </Heading>
                    </Box>
                    <Box>
                    <Text p="4">{item.brief_project_description.text}</Text>
                    </Box>
                    <Box mt="auto" pb="4">
                        <Link to={item.project_link.url} as={GatsbyLink}>
                        <Button variant="ghost" fontWeight="700" _hover={{ bg: 'none', color: 'sunset.400', textDecoration: 'none !important'}} _active={{background: "none !important"}}>
                            Learn More <span style={{marginLeft: '5px'}}><FaChevronRight /></span>
                        </Button>
                        </Link>
                        </Box>
                    </Box>
                </Flex>
            </Box>

<Box
display={{md: 'block', lg: 'none'}}
width={{base: '100%', lg: '50%'}}
px={{base: 0, lg: '1'}}
mb="2"
>
<Flex
    flexDirection="column"
    flexGrow="1"
    justifyContent="space-between"
    color="white"
    textAlign="center"
    h="100%"
    bg="brand.400"
>
    <Box>
        <GatsbyImage image={getImage(item.project_image.localFile)} alt={item.project_image.alt} />
    </Box>
    <Box p="4">
        <Heading as="h4" 
        fontSize="1.5rem"
        >
            {item.project_title.text}
        </Heading>
    </Box>
    <Box px="4">
    <Text>{item.brief_project_description.text}</Text>
    </Box>
    <Box mt="auto" pb="4">
        <Link to={item.project_link.uid} as={GatsbyLink}>
        <Button variant="project-card-button" fontWeight="700" _hover={{ bg: 'none', color: 'sunset.400', textDecoration: 'none !important'}} _active={{background: "none !important"}}>
            Learn More <span style={{marginLeft: '5px'}}><FaChevronRight /></span>
        </Button>
        </Link>
        </Box>
</Flex>
</Box>
</>
            )
            : 
            (
                
                <Box
                width={{base: '100%', lg: '50%'}}
                px={{base: 0, lg: '1'}}
                mb="2"
            >
                <Flex
                    flexDirection="column"
                    flexGrow="1"
                    justifyContent="space-between"
                    color="white"
                    textAlign="center"
                    h="100%"
                    bg="brand.400"
                >
                    <Box>
                        <GatsbyImage image={getImage(item.project_image.localFile)} alt={item.project_image.alt} />
                    </Box>
                    <Box p="4">
                        <Heading as="h4" 
                        fontSize="1.5rem"
                        >
                            {item.project_title.text}
                        </Heading>
                    </Box>
                    <Box px="4">
                    <Text>{item.brief_project_description.text}</Text>
                    </Box>
                    <Box mt="auto" pb="4">
                        <Link to={item.project_link.uid} as={GatsbyLink}>
                        <Button variant="project-card-button" fontWeight="700" _hover={{ bg: 'none', color: 'sunset.400', textDecoration: 'none !important'}} _active={{background: "none !important"}}>
                            Learn More <span style={{marginLeft: '5px'}}><FaChevronRight /></span>
                        </Button>
                        </Link>
                        </Box>
                </Flex>
                </Box>
                 )
            }
        
                
        </>
            )
        })} 
        </Flex>
        </Box>
    )
}

export default ProjectCards