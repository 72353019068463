import React from 'react'
import { Box, Tabs, TabList, Tab, TabPanels, TabPanel, Heading, Text, Accordion, AccordionButton, AccordionItem, AccordionPanel, AccordionIcon, List, ListItem, ListIcon } from "@chakra-ui/react"
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { RichText } from 'prismic-reactjs'
import { Elements } from "prismic-richtext"
import { CustomLink } from '../../utils/customLink'
import { CheckCircleIcon } from '@chakra-ui/icons'
import { linkResolver } from '../../utils/linkResolver'
import { navigate } from 'gatsby-link'

// For future reference: 
const htmlSerializer = function (type, element, content, children, key) {
  switch (type) {
      case Elements.hyperlink:
        return (
          <a
            key={key}
            href={element.data.url || linkResolver(element.data)}
            target='_blank'
            rel={element.data.target ? 'noopener' : undefined}
            className="link-class"
          >
            {children}
          </a>
        )
    case Elements.list: 
      return <List spacing={3}>
                {children}
              </List>
    
    case Elements.listItem: 
      return <ListItem>
                <ListIcon as={CheckCircleIcon} color="steel.700" style={{marginBottom: '4px'}} />
                  {children}
            </ListItem>
  }
}

const OurWorkTabs = ({slice, location}) => {
    console.log(`Location hash: ${location}`)
    return (
        <Box backgroundColor="rgba(255, 255, 255, 0.8)" pt="4">
        <Box mb="4" id="boxen">
            <Heading as="h1" color="red.400" textAlign="center" marginBottom="4" fontSize={{sm: '1.5rem', md: '2rem'}}>
                {slice.primary.tab_section_title.text}
            </Heading>
            <Heading as="h3" color="brand.400" fontWeight="300" textAlign="center" fontSize={{sm: '1rem', md: '1.75rem'}}>
                {slice.primary.tab_section_subtitle.text}
            </Heading>
        </Box>
        <Box>
          {/* get the hash from the location object to determine which tab to activate */}
        <Tabs orientation="vertical" className="vertical-tabs" display={{sm: 'none', md: 'flex'}}
          defaultIndex={ 
            location.includes('#citizen-handbooks') ? 0 
            : location.includes('#tips-for-public-speaking') ? 1
            : location.includes('#contact-local-decision-makers') ? 2
            : location.includes('#teton-view-regional-plan') ? 3
            : location.includes('#helpful-links') ? 4
            : 0
          }
        >
        <TabList bg="steel.900" className='tab-list'>
          {console.log(slice)}
          {slice.items.map((tabContent, index, location) => {
            return (
              <Tab className="vertical-tab" p="6" color="white" key={index} id={index} onClick={() => navigate(`/resources#${tabContent.tab_id.text}`, { replace: true })}>
                <Heading mb="1rem" fontSize="1.25rem" textAlign="left">
                  {tabContent.tab_title.text}
                </Heading>
                <Text mb="1rem" textAlign="left">
                  {tabContent.tab_subtitle.text}
                </Text>
              </Tab>
            )
          })}

        </TabList>

        <TabPanels bg="steel.300" width="60%" className="tab-content">
          {slice.items.map((tabContent) => {
            //console.log(tabContent)
              return (
                <TabPanel p="0">
                    <Box>
                    <GatsbyImage image={getImage(tabContent.tab_image.localFile)} alt={tabContent.tab_image.alt} />
                    </Box>
                    <Box color="steel.900" p="8" className="tab-text">
                      <Heading as="h2" fontSize="1.5rem" marginBottom="4" textAlign="center">
                        {tabContent.tab_title.text}
                      </Heading>
                        <RichText
                          render={tabContent.tab_content.raw}
                          serializeHyperlink={CustomLink}
                        />
                    </Box>
                </TabPanel>
              )
            })}
        </TabPanels>
      </Tabs>
        </Box>
        <Box display={{sm: 'block', md: 'none'}}>
        <Accordion allowToggle borderColor="steel.200">
             {slice.items.map((tabContent) => {
         return (
       <AccordionItem borderTopColor="steel.200">
         <h2>
           <AccordionButton bg="steel.900" color="white">
             <Box flex="1" textAlign="left">
             {tabContent.tab_title.text}
             </Box>
             <AccordionIcon />
           </AccordionButton>
         </h2>
         <AccordionPanel p={0}>
         <Box>
                    <GatsbyImage image={getImage(tabContent.tab_image.localFile)} alt={tabContent.tab_image.alt} />
                    </Box>
                    <Box bg="steel.300" className="tab-text" p="2">
                        <RichText serializeHyperlink={CustomLink} render={tabContent.tab_content.raw} />
                    </Box>
         </AccordionPanel>
       </AccordionItem>
         )})
         }
         </Accordion>
         </Box>
         </Box>
    )
}

export default OurWorkTabs