import React from 'react'
import BlogPostSliceBlock from '../Slices/BlogPostSliceBlock'
import GetInvolvedBlock from '../Slices/GetInvolvedBlock'
import TeamPromoBlock from '../Slices/TeamPromoBlock'
import OurWorkTabs from '../Slices/OurWorkTabs'
import ProjectCards from '../Slices/ProjectCards'
import ImageGalleryBlock from '../Slices/ImageGalleryBlock'
import StaffBoardSlice from '../Slices/StaffBoardSlice'
import MilestonesBlock from '../Slices/MilestonesBlock'
import Testimonials from '../Slices/Testimonials'
import NewsletterBlock from '../Slices/NewsletterBlock'

// passing in the location prop from Resources.js so we can automatically select tabs on that page based on the url
const SliceZone = ({ sliceZone, location }) => {
    const sliceComponents = {
        call_to_action: GetInvolvedBlock,
        team_promo_block: TeamPromoBlock,
        blog_post_content: BlogPostSliceBlock,
        our_work_tabs: OurWorkTabs,
        our_work_cards: ProjectCards,
        image_gallery: ImageGalleryBlock,
        staff_board_member: StaffBoardSlice,
        milestones: MilestonesBlock,
        testimonial_slice: Testimonials,
        newsletter_sign_up: NewsletterBlock

    }
    const pageLocation = location
    console.log(`location: ${pageLocation}`)

    const sliceZoneContent = sliceZone.map((slice, index, location ) => {
        const SliceComponent = sliceComponents[slice.slice_type]
        if (SliceComponent) {
          
          return <SliceComponent slice={slice} key={`slice-${index}`} location={pageLocation} />
        }
        return null
      })
    
      return <div>{sliceZoneContent}</div>
    }

export default SliceZone