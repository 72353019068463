import React from 'react'
import styled from '@emotion/styled'


const Logo = styled.svg `
  width: ${props => props.width ? props.width : '315px' };
  height: auto;
  .cls-1 {
        fill: none;
      }

      .cls-2 {
        fill: #fff;
      }
`;

const WindowIcon = props => {
    return (
  <Logo xmlns="http://www.w3.org/2000/svg" viewBox="0 0 315 315" {...props}>
  <g id="Layer_2" data-name="Layer 2">
    <g id="Layer_1-2" data-name="Layer 1">
      <rect class="cls-1" width="315" height="315"/>
      <path class="cls-2" d="M209.19162,97.221c-.21021,11.41844-.46734,25.49868-.7314,39.57881-.03224,1.71908-.22464,3.44516-.11844,5.154.10137,1.6307-.39995,2.1309-2.07429,2.113-8.06386-.08631-16.12914-.03884-24.19392-.03892-4.22813,0-8.45968-.1-12.68285.04466-1.91547.06563-2.50315-.4142-2.48987-2.42088.09274-14.01472.04881-28.03031.04883-42.04563,0-17.46041.03894-34.921-.05092-52.381-.0107-2.07825.51067-2.58509,2.58157-2.56815,12.84015.10508,25.68175.08358,38.52241.017,1.74663-.00906,2.43885.29364,2.38378,2.266C209.94341,62.8113,209.61557,78.68589,209.19162,97.221Z"/>
      <path class="cls-2" d="M105.85594,211.738c0-14.9428.02732-29.88576-.0382-44.82828-.00732-1.67152.48922-2.07781,2.09037-1.99611q19.90245,1.01543,39.81262,1.873c1.77451.07487,1.55335,1.10071,1.52351,2.18676-.26409,9.61081-.55535,19.22088-.79788,28.83223q-.565,22.39047-1.08128,44.78219c-.12152,5.00195-.3085,10.00293-.36477,15.00548-.01719,1.52928-.54036,1.95473-2.05519,1.91709-12.66373-.31487-25.32882-.595-37.99513-.763-1.8583-.02467-1.73328-.95754-1.73211-2.19083q.02126-22.40922.00919-44.81847Z"/>
      <path class="cls-2" d="M205.17374,211.21217c0,15.49794-.0366,30.99607.047,46.49355.01026,1.90235-.55991,2.29848-2.35094,2.28741q-18.43269-.11424-36.8662.0066c-1.79917.01322-2.168-.48291-2.1473-2.1986.24331-20.18333.39347-40.36775.59277-60.55162.1035-10.47992.29447-20.9591.3455-31.43913.0079-1.62064.40249-2.05587,2.05457-2.088,11.7255-.22816,23.44865-.57848,35.17246-.893,3.1518-.08456,3.152-.08943,3.15206,3.06309Q205.1739,188.5523,205.17374,211.21217Z"/>
      <path class="cls-2" d="M106.95181,97.9155c0-15.5657.04706-31.13172-.05711-46.69672-.01366-2.04023.573-2.38218,2.43889-2.31939q17.46162.58755,34.93087.92811c1.66092.03171,1.86781.6235,1.87807,2.04189q.168,23.23188.44246,46.463.24372,22.64449.5578,45.288c.01787,1.1573-.21531,1.48256-1.34206,1.53929q-18.14934.91406-36.29294,1.93706c-1.56218.09041-1.5575-.63765-1.55571-1.78463q.03706-23.69838.01667-47.39679Z"/>
      <path class="cls-2" d="M106.95186,97.91552q.50847-.00021,1.01692-.00014,0,23.69835-.01674,47.39668c-.00173,1.147-.00638,1.875,1.55575,1.78469q18.14193-1.0498,36.29292-1.93711c1.12681-.05674,1.36-.38189,1.34207-1.53926q-.3496-22.643-.55782-45.288-.24994-23.23113-.44249-46.46307c-.01023-1.41834-.21712-2.01-1.878-2.04181q-17.46823-.33366-34.93091-.92816c-1.86588-.06272-2.45254.27918-2.43886,2.3194C106.9989,66.78374,106.95186,82.34983,106.95186,97.91552Z"/>
      <path class="cls-2" d="M147.72067,166.7866q-19.91049-.84014-39.81261-1.87306c-1.60106-.08159-2.09763.32462-2.09032,1.9961.06551,14.94262.03814,29.88551.03814,44.82839h-.62879q0,22.40914-.00916,44.81843c-.0012,1.23325-.12624,2.16619,1.73207,2.1909,12.66627.168,25.33135.44807,37.9951.763,1.51482.03761,2.038-.38787,2.05524-1.91717.0562-5.00249.24316-10.00352.36475-15.00547q.54393-22.391,1.08123-44.78215c.24251-9.61139.53378-19.22145.79794-28.83231C149.274,167.88723,149.49527,166.86141,147.72067,166.7866Z"/>
      <path class="cls-2" d="M208.00273,44.67391c-12.84061.06657-25.68228.0881-38.52236-.017-2.07091-.017-2.59233.48992-2.58157,2.56815.08983,17.45987.0509,34.92054.0509,52.38095,0,14.01539.04385,28.03092-.0489,42.04564-.01329,2.00674.57443,2.48656,2.48988,2.42092,4.22315-.1447,8.45481-.04478,12.68288-.04464,8.06481,0,16.13-.04744,24.19391.03893,1.6744.0178,2.17562-.48235,2.07437-2.113-.1063-1.70882.0861-3.43492.11839-5.154.264-14.08024.52115-28.16047.73137-39.57888.424-18.53513.75183-34.40963,1.195-50.28107C210.44159,44.96757,209.74942,44.66487,208.00273,44.67391Z"/>
      <path class="cls-2" d="M205.17374,211.2121q0-22.65988,0-45.31964c-.00013-3.15255-.00026-3.14764-3.15215-3.06312-11.72376.31452-23.44686.66479-35.17235.89294-1.65208.03216-2.04673.46747-2.05457,2.08806-.051,10.48-.24211,20.95924-.34562,31.43912-.19919,20.18389-.34934,40.36831-.59277,60.55167-.0206,1.7156.34814,2.21177,2.14732,2.19862q18.43254-.13554,36.86629-.00665c1.79094.011,2.36112-.38508,2.35089-2.28737C205.1372,242.20821,205.17374,226.71,205.17374,211.2121Z"/>
      <path class="cls-2" d="M109.33358,48.89932q17.46166.58759,34.93091.92816c1.66086.03176,1.86775.62347,1.878,2.04181q.168,23.23194.44249,46.46307.24376,22.64454.55782,45.288c.01794,1.15737-.21526,1.48252-1.34207,1.53926q-18.14933.91408-36.29292,1.93711c-1.56213.09036-1.55748-.63768-1.55575-1.78469q.03708-23.69833.01674-47.39668-.50845,0-1.01692.00014c0-15.56569.047-31.13178-.05714-46.6968C106.881,49.1785,107.4677,48.8366,109.33358,48.89932Z"/>
      <path class="cls-2" d="M149.24426,168.97325c-.26416,9.61086-.55543,19.22092-.79794,28.83231q-.56487,22.39041-1.08123,44.78215c-.12159,5.002-.30855,10.003-.36475,15.00547-.01728,1.5293-.54042,1.95478-2.05524,1.91717-12.66375-.31492-25.32883-.595-37.9951-.763-1.85831-.02471-1.73327-.95765-1.73207-2.1909q.02112-22.40915.00916-44.81843h.62879c0-14.94288.02737-29.88577-.03814-44.82839-.00731-1.67148.48926-2.07769,2.09032-1.9961q19.90251,1.01553,39.81261,1.87306C149.49527,166.86141,149.274,167.88723,149.24426,168.97325Z"/>
      <path class="cls-2" d="M202.86989,259.9931q-18.43275-.11421-36.86629.00665c-1.79918.01315-2.16792-.483-2.14732-2.19862.24343-20.18336.39358-40.36778.59277-60.55167.10351-10.47988.29459-20.9591.34562-31.43912.00784-1.62059.40249-2.0559,2.05457-2.08806,11.72549-.22815,23.44859-.57842,35.17235-.89294,3.15189-.08452,3.152-.08943,3.15215,3.06312q.00021,22.65969,0,45.31964c0,15.49792-.03654,30.99611.047,46.49363C205.231,259.608,204.66083,260.00413,202.86989,259.9931Z"/>
      <path class="cls-2" d="M209.1916,97.221c-.21022,11.41841-.46734,25.49864-.73137,39.57888-.03229,1.71905-.22469,3.44515-.11839,5.154.10125,1.63069-.4,2.13084-2.07437,2.113-8.06388-.08637-16.1291-.03893-24.19391-.03893-4.22807-.00014-8.45973-.10006-12.68288.04464-1.91545.06564-2.50317-.41418-2.48988-2.42092.09275-14.01472.0489-28.03025.0489-42.04564,0-17.46041.03893-34.92108-.0509-52.38095-.01076-2.07823.51066-2.58516,2.58157-2.56815,12.84008.10511,25.68175.08358,38.52236.017,1.74669-.009,2.43886.29366,2.38385,2.266C209.94343,62.81133,209.61562,78.68583,209.1916,97.221Z"/>
    </g>
  </g>
</Logo>
)
}

export default WindowIcon;