import React from "react"

import Gallery from '@browniebroke/gatsby-image-gallery'

import { Box } from "@chakra-ui/react"

const ImageGalleryBlock = ({ slice }) => {
  
  //const images = slice.items.map((item) => getImage(item.localFile))
  //const images = slice.items.map((item) => item.image.localFile.childImageSharp)
  //const caption = slice.items.map((captionItem) => captionItem.image_caption.text)

  const images = slice.items.map(( node ) => ({
      ...node.image.localFile.childImageSharp,
      caption: node.image_caption.text
  }))
  
  return (
    <Box backgroundColor="rgba(255, 255, 255, 0.8)" p="4">
        <Gallery images={images}
            className="gallery"
        />
      {/* <Flex flexWrap="wrap">
        {images.map(item => {
          return (
            <Box w={{sm: '50%', md: '25%'}} backgroundColor="rgba(255, 255, 255, 0.8)" p="4">
              <GatsbyImage
                image={getImage(item.image.localFile)}
                alt={item.alt}
              />
            </Box>
          )
        })}
      </Flex> */}
    </Box>
  )
}

export default ImageGalleryBlock
