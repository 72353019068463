import React from 'react'
import { Link } from 'gatsby'

import { linkResolver } from './linkResolver'

export const CustomLink = (type, element, content, children) => {
  // console.log(`Element: ${JSON.stringify(element)}`)
    if (element.data.link_type === 'Document') {
        console.log(element.data.link_type)
        return (
        <Link to={linkResolver(element.data)} key={element.data.id}>
          {content}
        </Link>
        )
      }
    if (element.data.link_type === 'Media') {
        return (
        <a href={element.data.url} target="_blank" rel="noopener noreferrer">
            <strong>{content}</strong>
        </a>
        )
    }
    if (element.data.link_type === 'Web') {
        return (
        <a href={element.data.url} target="_blank" rel="noopener noreferrer">
            {content}
        </a>
        )
    }
    return null
}