import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { Link as GatsbyLink } from 'gatsby'
import { RichText } from 'prismic-reactjs'
import { AnchorLink } from "gatsby-plugin-anchor-links"

import { 
    Box,
    Heading,
    Text,
    Flex,
    Link
} from '@chakra-ui/react'
import { ChevronRightIcon } from '@chakra-ui/icons'

const TeamPromoBlock = ({ slice }) => {
      return (
        <Box maxW="100%" bg="steel.900" p="8">
           <Flex w="100%"
            //alignItems="center"
            flexWrap="wrap"
           >
            <Box w={{sm: '100%', md: '38%'}} 
              marginRight={{sm: 0, md: '1rem'}}
              marginBottom={{sm: '1rem', md: 0}}
            ><GatsbyImage image={getImage(slice.primary.team_photo.localFile)} alt="" /></Box>
            <Box w={{sm: '100%', md: '60%'}} textAlign={{sm: 'center', md: 'left'}}>
              <Heading color="white" fontSize="2rem" mb="4">
                {slice.primary.team_title.text}
              </Heading>
              <Box color="white" fontSize="1rem" fontWeight="600">
              <RichText render={slice.primary.paragraph.raw} />
              </Box>
              <Text fontSize="1.5rem"  textAlign={{sm: 'center', md: 'right'}}>
                  <AnchorLink to={`${slice.primary.link_to_team.uid}${'#staff'}`} color="steel.200" fontWeight="bold" className="stripped"
                stripHash>Meet the team <ChevronRightIcon /></AnchorLink>
              </Text>
            </Box>
            </Flex>
        </Box>
    )
}


export default TeamPromoBlock