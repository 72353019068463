import React from 'react'
import { Button } from '@chakra-ui/react'
import { Link } from 'gatsby'

const TakeActionButton = (props) => {

        return (
        // this method works with the linkResolver function only if linking to dynamic pages
        // otherwise, we need to explicitly format the URL
        // <Link to={props.linkTo}>
        <Link to={`/${props.linkTo}`}>
            <Button
              width={props.width}
              size={props.size}
              colorScheme={props.colorScheme}
              textTransform="uppercase"
            >
              {props.btnText}
            </Button>
          </Link>
        )
}

export default TakeActionButton